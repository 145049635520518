import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AuthContext } from '../AuthContext';
import { getDoc, doc, setDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebase';
import Modal from './modal'
import './BingoPage.css';

const GAME_DOC_ID = "singleGame"; // Fixed ID for the single game document

const createDeck = () => {
  const suits = ['clubs', 'diamonds', 'hearts', 'spades'];
  const values = [2, 3, 4, 5, 6, 7, 8, 9, 10, 'jack', 'queen', 'king', 'ace'];
  let deck = [];
  for (let suit of suits) {
    for (let value of values) {
      deck.push({ cardValue: value, suit });
    }
  }
  return deck;
};

const shuffleDeck = (deck) => {
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [deck[i], deck[j]] = [deck[j], deck[i]];
  }
  return deck;
};

const createNewGame = async () => {
  const deck = shuffleDeck(createDeck());
  const gameDocRef = doc(firestore, 'games', GAME_DOC_ID);
  const newGameData = {
    startTime: new Date(),
    calledCards: [],
    currentCard: null,
    deck: deck,
  };

  await setDoc(gameDocRef, newGameData);
};

const BingoPage = ({ setCardsCalled }) => {
  const { currentUser } = useContext(AuthContext);
  const [isHost, setIsHost] = useState(false);
  const [calledCards, setCalledCards] = useState([]);
  const [currentCard, setCurrentCard] = useState(null);
  const [deck, setDeck] = useState([]);
  const [roundFinished, setRoundFinished] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const drawCard = useCallback(async () => {
    if (deck.length === 0) {
      setRoundFinished(true);
      setModalVisible(true);
      return;
    }

    const newCard = deck[0];
    const updatedDeck = deck.slice(1);
    const updatedCalledCards = [...calledCards, newCard];

    setDeck(updatedDeck);
    setCalledCards(updatedCalledCards);
    setCurrentCard(newCard);

    const gameDocRef = doc(firestore, 'games', GAME_DOC_ID);
    await setDoc(gameDocRef, { calledCards: updatedCalledCards, currentCard: newCard, deck: updatedDeck }, { merge: true });
  }, [calledCards, deck]);

  useEffect(() => {
    setCardsCalled(calledCards.length);
  }, [calledCards, setCardsCalled]);

  useEffect(() => {
    const checkIsHost = async () => {
      if (currentUser) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && userDoc.data().role === 'host') {
          setIsHost(true);
        } else {
          setIsHost(false);
        }
      }
    };

    const fetchGame = () => {
      const gameDocRef = doc(firestore, 'games', GAME_DOC_ID);
      const unsubscribe = onSnapshot(gameDocRef, (doc) => {
        if (doc.exists()) {
          const gameData = doc.data();
          setCalledCards(gameData.calledCards || []);
          setCurrentCard(gameData.currentCard || null);
          setDeck(gameData.deck || shuffleDeck(createDeck()));
        }
      });

      return () => unsubscribe();
    };

    checkIsHost();
    fetchGame();
  }, [currentUser]);

  const getCardImage = useCallback((card) => {
    if (!card) return null;
    try {
      return require(`../assets/cards/${card.cardValue}_of_${card.suit}.png`);
    } catch (error) {
      console.error('Card image not found:', error);
      return null;
    }
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setFullscreen(!fullscreen);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className={`bingoPage ${fullscreen ? 'fullscreen' : ''}`}>
      <Modal isVisible={modalVisible} onClose={closeModal}>
        <h2>All cards have been called!</h2>
        <button className="fancy-button" onClick={closeModal}>Close</button>
      </Modal>
      <div className="left-section">
        <h1>Eagles Bingo</h1>
        <div className="called-cards-counter">
          <h2>{calledCards.length} Cards Called</h2>
        </div>
        {isHost && (
          <div className="button-container">
            <button className="fancy-button" onClick={drawCard}>Draw Card</button>
            <button className="fancy-button" onClick={createNewGame}>New Game</button>
          </div>
        )}
        <div><button className="fancy-button" onClick={toggleFullscreen}>
              {fullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
            </button></div>
        <div className="current-card-section">
          <h3>Current Card:</h3>
          {currentCard && (
            <img src={getCardImage(currentCard)} alt={`Card ${currentCard.cardValue} of ${currentCard.suit}`} className="current-card" />
          )}
        </div>
      </div>
      <div className="right-section">
        <div className="called-cards-section">
          <div className="called-cards">
            {calledCards.map((card, index) => {
              const row = Math.floor(index / 18);
              const col = index % 18;
              return (
                <img
                  key={index}
                  src={getCardImage(card)}
                  alt={`Card ${card.cardValue} of ${card.suit}`}
                  className="called-card"
                  style={{ left: `${col * 70}px`, top: `${row * 275}px` }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BingoPage;

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, getDoc, collection, query, orderBy, limit, updateDoc, getDocs, setDoc, serverTimestamp, doc, addDoc, deleteDoc, Timestamp, where } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase, ref, remove, set }  from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCEgdYfaICMAeDC0VXA7SbCDuo765gq5wM",
  authDomain: "ypsi2250-cd814.firebaseapp.com",
  projectId: "ypsi2250-cd814",
  storageBucket: "ypsi2250-cd814.appspot.com",
  messagingSenderId: "701364034723",
  appId: "1:701364034723:web:b2dc8b5f6eeef9e09bf316",
  measurementId: "G-06PS5SPPLY",
  databaseURL: "https://ypsi2250-cd814.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get auth instance
const auth = getAuth(app);

// Get Firestore instance
const firestore = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

// Correct initialization of Realtime Database
const database = getDatabase(app);

export { storage, firestore, auth, app, database, Timestamp, where, ref, remove, doc, updateDoc, addDoc, deleteDoc, serverTimestamp, limit, query, orderBy, getDocs, setDoc, getDoc, collection, set };
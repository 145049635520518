import React, { useState, useEffect } from 'react';
import { firestore, storage, auth } from '../firebase.js';
import { collection, query, where, orderBy, getDocs, Timestamp, doc, getDoc, addDoc, deleteDoc } from '../firebase.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import './EventsPage.css';

const localizer = momentLocalizer(moment);

function EventsPage() {
    const [events, setEvents] = useState([]);
    const [showAddEventPopup, setShowAddEventPopup] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [newEvent, setNewEvent] = useState({ name: '', eventDate: '', eventTime: '12:00', image: null, description: '', category: 'Aerie' });
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showEventPopup, setShowEventPopup] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const userRef = doc(firestore, "users", user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists() && userSnap.data().isA) {
                    setIsAdmin(true);
                }
            } else {
                setIsAdmin(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchEvents = async () => {
        const currentDate = Timestamp.fromDate(new Date());
        const collections = ["Aerie", "Auxiliary", "EagleRiders"];
        let allEvents = [];

        for (const collectionName of collections) {
            const q = query(
                collection(firestore, collectionName),
                where("EventDate", ">=", currentDate),
                orderBy("EventDate")
            );

            const querySnapshot = await getDocs(q);
            const fetchedEvents = querySnapshot.docs.map(doc => {
                const eventData = doc.data();
                return {
                    id: doc.id,
                    title: eventData.Name,
                    start: eventData.EventDate.toDate(),
                    end: eventData.EventDate.toDate(),
                    allDay: true,
                    image: eventData.Image,
                    description: eventData.EventDescription,
                    category: collectionName
                };
            });

            allEvents = [...allEvents, ...fetchedEvents];
        }

        setEvents(allEvents);
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({ ...newEvent, [name]: value });
    };

    const handleImageChange = (e) => {
        setNewEvent({ ...newEvent, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, eventDate, eventTime, image, category, description } = newEvent;

        let imageUrl = null;
        if (image) {
            const imageRef = ref(storage, `eventImages/${image.name}`);
            const snapshot = await uploadBytes(imageRef, image);
            imageUrl = await getDownloadURL(snapshot.ref);
        }

        const eventDateTime = new Date(eventDate + 'T' + eventTime);

        await addDoc(collection(firestore, category), {
            Name: name,
            EventDate: Timestamp.fromDate(eventDateTime),
            EventDescription: description,
            ...(imageUrl && { Image: imageUrl })
        });

        setShowAddEventPopup(false);
        fetchEvents(); // Refresh the events list
    };

    const handleDelete = async () => {
        if (selectedEvent) {
            const eventDocRef = doc(firestore, selectedEvent.category, selectedEvent.id);
            await deleteDoc(eventDocRef);
            setShowEventPopup(false);
            fetchEvents(); // Refresh the events list
        }
    };

    const renderEventPopup = () => {
        if (!showEventPopup || !selectedEvent) {
            return null;
        }

        return (
            <div className="popup">
                <h2>{selectedEvent.title}</h2>
                <p>{selectedEvent.description || "No description available"}</p>
                {selectedEvent.image && <img src={selectedEvent.image} alt="Event" />}
                <button onClick={() => setShowEventPopup(false)}>Close</button>
                {isAdmin && (
                    <button className="fancy-delete-button" onClick={handleDelete}>Delete Event</button>
                )}
            </div>
        );
    };

    return (
        <div className="eventsPage">
            <h1>Events</h1>
            {isAdmin && (
                <button className="addEventButton" onClick={() => setShowAddEventPopup(true)}>Add Event</button>
            )}
            {showAddEventPopup && (
                <div className="popup">
                    <form onSubmit={handleSubmit}>
                        <input type="text" name="name" value={newEvent.name} onChange={handleInputChange} placeholder="Event Name" required />
                        <textarea name="description" value={newEvent.description} onChange={handleInputChange} placeholder="Event Description" />
                        <input type="date" name="eventDate" value={newEvent.eventDate} onChange={handleInputChange} required />
                        <input type="time" name="eventTime" value={newEvent.eventTime} onChange={handleInputChange} required />
                        <select name="category" value={newEvent.category} onChange={handleInputChange}>
                            <option value="Aerie">Aerie</option>
                            <option value="Auxiliary">Auxiliary</option>
                            <option value="EagleRiders">Eagle Riders</option>
                        </select>
                        <input type="file" onChange={handleImageChange} />
                        <button type="submit">Create Event</button>
                    </form>
                    <button onClick={() => setShowAddEventPopup(false)}>Close</button>
                </div>
            )}
            <div className="calendar-container">
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={(event) => {
                        setSelectedEvent(event);
                        setShowEventPopup(true);
                    }}
                />
            </div>
            {renderEventPopup()}
        </div>
    );
}

export default EventsPage;
